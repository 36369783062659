@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Urbanist:ital,wght@0,100..900;1,100..900&display=swap');

/* Open Sans  */
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');
/* font-family: "Open Sans", sans-serif;  */

.Open-Sans {
  font-family: 'Open Sans';
}

/*  "Barlow" */
@import url('https://fonts.googleapis.com/css2?family=Barlow:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
/* font-family: "Barlow", sans-serif; */

.Barlow {
  font-family: 'Barlow';
}

body,
html {
  font-family: 'Outfit', sans-serif;
}

.btn.bg-indigo-500 {
  background-color: rgb(99 102 241 / var(--tw-bg-opacity)) !important;
}

.btn.bg-indigo-500:hover {
  background-color: rgb(79 70 229 / var(--tw-bg-opacity)) !important;
}

.basic-multi-select .reactSelect__control {
  background-color: transparent;
}

.transform-y--100 {
  transform: translateY(-100%);
  top: -10px;
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {

  select,
  textarea,
  input {
    font-size: 16px !important;
  }

  input:focus,
  select:focus,
  textarea:focus {
    font-size: 16px;
  }
}

/* Creatable Dropdown */

.creatableDropdown__input:focus,
.searchDropdown__input:focus {
  box-shadow: none;
}

/* Custom Scrollbar */

.scrollbar::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

.scrollbar::-webkit-scrollbar-track {
  background: rgba(208, 213, 221, 0.5);
}

.scrollbar::-webkit-scrollbar-thumb {
  background: #e48642;
}

.scrollbar::-webkit-scrollbar-thumb:hover {
  background: #e48642;
}

/* Custom Mention */

.__highlight__ {
  color: #e48642 !important;
  border-radius: 20px;
  background: rgba(228, 134, 66, 0.2);
  display: flex;
  padding: 0px 10px 0px 8px;
  justify-content: center;
  align-items: center;
}

/* React Slick Carousel  */
.slick-dots li button:before {
  font-size: 10px !important;
  color: white !important;
}

.slick-dots li {
  margin: 0 !important;
}

.slick-dots li.slick-active {
  margin: 0 7px !important;
}

.slick-dots li.slick-active button:before {
  height: 8px;
  width: 24px;
  background-color: white;
  border-radius: 15px;
  content: '' !important;
  margin: 5px 0;
  opacity: 1 !important;
}

.flatpickr-wrapper {
  width: 100%;
}

/* Checkbox styles  */

.form-checkbox-orange {
  height: 20px;
  width: 20px;
  position: relative;
}

.form-checkbox-orange:checked {
  background-image: url('../images/orange-check.svg') !important;
  background-color: transparent !important;
  border: 1px solid #e48642 !important;
  border-color: #e48642 !important;
  background-size: contain !important;
}

.form-checkbox-orange:focus,
.form-checkbox-orange:active,
.form-checkbox-orange:visited {
  border-color: #e48642 !important;
}

.form-checkbox {
  height: 20px;
  width: 20px;
  position: relative;
}

.form-checkbox:checked {
  background-image: url('../images/green-check.svg') !important;
  background-color: transparent !important;
  border: 1px solid #78bd4f !important;
  border-color: #78bd4f !important;
  background-size: contain;
}

.form-checkbox:focus,
.form-checkbox:active,
.form-checkbox:visited {
  border-color: #78bd4f !important;
}

/* Remove Arrows  */

.remove-arrows::-webkit-inner-spin-button,
.remove-arrow::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.remove-arrows {
  -moz-appearance: textfield;
}

/* Transation Active Row  */

.activeTransactionRow:hover .transactionRowCategories>div {
  display: block;
}

.activeTransactionRow:hover .transactionRowCategories>span {
  display: none;
}

/* Tooltip Arrow  */
.tooltipArrowDown::before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 100%);
  height: 13px;
  width: 1.5rem;
  background: #fff;
  box-shadow:
    0 4px 6px -1px rgba(0, 0, 0, 0.08),
    0 2px 4px -1px rgba(0, 0, 0, 0.02);
  clip-path: polygon(50% 50%, 0 0, 100% 0);
}

.tooltipArrowUp::before {
  content: '';
  position: absolute;
  top: 0px;
  left: 50%;
  transform: translate(-50%, -100%);
  height: 13px;
  width: 1.5rem;
  background: #fff;
  box-shadow:
    0 4px 6px -1px rgba(0, 0, 0, 0.08),
    0 2px 4px -1px rgba(0, 0, 0, 0.02);
  clip-path: polygon(50% 50%, 0 100%, 100% 100%);
}

.tooltipArrowLeft {
  box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.1) !important;
}

.tooltipArrowLeft::before {
  content: '';
  position: absolute;
  left: 2px;
  top: 50%;
  transform: translate(-100%, -50%);
  height: 1.25rem;
  width: 1rem;
  background: #fff;
  box-shadow:
    0 4px 6px -1px rgba(0, 0, 0, 0.08),
    0 2px 4px -1px rgba(0, 0, 0, 0.02);
  clip-path: polygon(100% 0, 50% 50%, 100% 100%);
}

.tooltipArrowRight {
  box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.1) !important;
}

.tooltipArrowRight::before {
  content: '';
  position: absolute;
  right: 2px;
  top: 50%;
  transform: translate(100%, -50%);
  height: 1.25rem;
  width: 1rem;
  background: #fff;
  box-shadow:
    0 4px 6px -1px rgba(0, 0, 0, 0.08),
    0 2px 4px -1px rgba(0, 0, 0, 0.02);
  clip-path: polygon(0 0, 50% 50%, 0 100%);
}

.tooltipArrowDown {
  box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.1) !important;
}

.tooltipArrowUp {
  box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.1) !important;
}

textarea:focus {
  outline: none !important;
  box-shadow: none !important;
}

/* Custom Text */
.filter-label {
  letter-spacing: 1.1px;
}

/* Custom Toast Colors */
.toast-text {
  color: #a0cd85;
}

.toast-bg {
  background-color: #d9ebce;
}

.toast-border {
  border: 1px solid #a0cd85;
}

.transactionsTableHeight {
  height: calc(100vh - 485px);
  min-height: 300px;
}

.header-subscription-warning {
  left: calc(50% - 128px);
  transform: translate(-50%, 0%);
}

.header-subscription-warning-workflows {
  left: calc(50%);
  transform: translate(-50%, 0%);
}

/* Animated Checkmark */
.path {
  stroke-dasharray: 1000;
  stroke-dashoffset: 0;

  &.line {
    stroke-dashoffset: 1000;
    -webkit-animation: dash 0.9s 0.35s ease-in-out forwards;
    animation: dash 0.9s 0.35s ease-in-out forwards;
  }

  &.check {
    stroke-dashoffset: -100;
    -webkit-animation: dash-check 0.9s 0.35s ease-in-out forwards;
    animation: dash-check 0.9s 0.35s ease-in-out forwards;
  }
}

.scrollbarRecieptModal::-webkit-scrollbar-track {
  border-radius: 0.75rem;
}

@-webkit-keyframes dash {
  0% {
    stroke-dashoffset: 1000;
  }

  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes dash {
  0% {
    stroke-dashoffset: 1000;
  }

  100% {
    stroke-dashoffset: 0;
  }
}

@-webkit-keyframes dash-check {
  0% {
    stroke-dashoffset: -100;
  }

  100% {
    stroke-dashoffset: 900;
  }
}

@keyframes dash-check {
  0% {
    stroke-dashoffset: -100;
  }

  100% {
    stroke-dashoffset: 900;
  }
}

/* Projects Calendar  */

.fc .fc-daygrid-day-top {
  justify-content: center;
}

.fc-scrollgrid-sync-table {
  height: fit-content !important;
}

.fc .fc-daygrid-day-frame {
  padding-bottom: 8px;
  min-height: 110px;
}

@media (min-width: 1400px) {
  .fc .fc-daygrid-day-frame {
    min-height: 130px;
  }
}

@media (min-width: 1600px) {
  .fc .fc-daygrid-day-frame {
    min-height: 140px;
  }
}

@media (min-width: 1800px) {
  .fc .fc-daygrid-day-frame {
    min-height: 180px;
  }
}

.fc-daygrid-body.fc-daygrid-body-unbalanced {
  border-bottom: 1px solid #d0d5dd;
}

.fc-theme-standard .fc-scrollgrid {
  border-left-width: 0px !important;
}

.fc-theme-standard td[role='row']:first-child {
  border-left-width: 1px;
}

.fc-theme-standard td[role='row']:last-child {
  border-right-width: 1px;
}

/* .fc-theme-standard td:first-child, .fc-theme-standard th:first-child {
  border-left-width: 1px ;
} */

.fc-scrollgrid-section.fc-scrollgrid-section-header>th {
  border-right-width: 0px;
}

.fc-theme-standard td[role='presentation'] {
  border-bottom-width: 0px !important;
  border-right-width: 0px !important;
}

.fc .fc-scrollgrid table {
  border-left-style: solid;
  border-right-style: solid;
}

/* Switch Input  */
.form-switch-indigo input[type='checkbox']:checked+label {
  background-color: #e48642 !important;
}

.form-switch-indigo input[type='checkbox']:disabled+label {
  background-color: #d0d5dd !important;
}

.form-switch-indigo input[type='checkbox']:checked:disabled+label {
  background-color: #EFB68E !important;
}

.form-switch-indigo label,
.form-switch-green label {
  background-color: #d0d5dd !important;
  border: none;
}

.form-switch-indigo label>span:first-child,
.form-switch-green label>span:first-child {
  background-color: white;
}

.form-switch-indigo input[type='checkbox']:disabled+label>span:first-child,
.form-switch-green input[type='checkbox']:disabled+label>span:first-child {
  background-color: #f1f5f9 !important;
}

.form-switch-green input[type='checkbox']:checked+label {
  background-color: #78BD4F !important;
}

.form-switch-green input[type='checkbox']:disabled+label {
  background-color: #78BD4F80 !important;
}

/* Task Slider */

/* slick-arrow slick-prev slick-disabled */
.taskAttachmentsSlider .slick-prev {
  left: 5px;
  z-index: 100;
}

.taskAttachmentsSlider .slick-next {
  right: 5px;
  z-index: 100;
}

.taskAttachmentsSlider .slick-prev:before,
.taskAttachmentsSlider .slick-next:before {
  display: none;
}

.slick-disabled {
  display: none !important;
}

.taskAttachmentsSlider .slick-dots li.slick-active button:before {
  color: #e48642 !important;
  background-color: #e48642 !important;
}

.taskAttachmentsSlider .slick-dots li button:before {
  color: #cbd5e1 !important;
}

/* .taskAttachmentsSlider .slick-track {
  margin-left: 0 ;
  margin-right: 0;
} */

/* .taskAttachmentsSlider .slick-dots li button:before:hover {
    color: red !important;
  }

  .slick-dots li button:hover, .slick-dots li button:focus {

  } */

.attachmentsSliderTransformNone .slick-track {
  transform: none !important;
  margin-left: 0 !important;
}

.taskAttachmentsSlider {
  margin: 0 auto;
  width: 100%;
  /* Adjust the width as necessary */
}

.taskAttachmentsSlider .slick-slide {
  padding: 0 5px;
  /* Adds gap between slides (left and right padding) */
}

.taskAttachmentsSlider .slick-list {
  margin: 0 -5px;
  /* Removes the padding from the first and last slide */
}

/* input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

input[type="number"] {
  -moz-appearance: textfield;
} */

.down-arrow-tooltip {
  position: relative;
}

.down-arrow-tooltip::before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 90%);
  height: 0.5rem;
  width: 1rem;
  border: 1px solid #e2e8f0;
  border-top: 0 !important;
  background-color: #fff;
  box-shadow:
    0 10px 15px -3px rgba(0, 0, 0, 0.08),
    0 4px 6px -2px rgba(0, 0, 0, 0.01);
  clip-path: polygon(50% 100%, 0 0, 100% 0);
}

.circularProgressBar {
  --size: 40px;
  width: var(--size);
  aspect-ratio: 1;
  border-radius: 50%;
  background: conic-gradient(#f00 20%, #ccc 0);
  display: grid;
  place-content: center;
}

.circularProgressBar:after {
  width: calc(var(--size) * 0.78);
  aspect-ratio: 1;
  content: attr(data-value);
  display: grid;
  background: #fff;
  border-radius: 50%;
  display: grid;
  font-size: 11px;
  font-weight: 500;
  color: #101828;
  place-content: center;
}

/* Custom Style for Mantine Table Tooltip  */
.mantine-Tooltip-tooltip {
  background-color: #ffffff !important;
  color: #667085 !important;
  border: none;
  font-family: 'Outfit', sans-serif !important;
  font-weight: normal !important;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.08), 0 4px 6px -2px rgba(0, 0, 0, 0.01);
}